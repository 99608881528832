.cross_icon{
    position: relative;
    margin-left: 470px;
    margin-top: 8px;
    font-size: 15px;
    color: #808080;

}
.cross_icon:hover{
    color: #3F4254;
    cursor: pointer;
}
.containerr{

width: 816px;
height: 540px;
display: flex;
flex-direction: row;

}
.containerr .text  {
width: 391px !important;
height: 540px !important;
display: flex;
flex-direction: column;
padding-top: 119px;
padding-left: 68px;
}
.containerr .img{
    width: 425px !important;
    height: 540px !important;
    /* background: #00AEBF !important; */
/* transform: rotate(-180deg); */
z-index: 1000;

    
    
}
.containerr .tt{
    width: 425px !important;
    height: 540px !important;
    background: #00AEBF !important;
    z-index: 5555 !important;
    position: absolute;
    right: -316px;
    opacity: 0.5;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;

/* transform:otate(-180deg); */
/* z-index: 1000; */

    
    
}
.containerr .img img{
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    /* transform: rotate(-180deg); */
    z-index: -1;
    
}

.heading .greeting{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 26px;
line-height: 35px;
display: block;

color: #000000;
}
.heading .user_name{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    
    color: #01ACBE;
}
.text .decs{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    
    color: #000000;
    margin-bottom:48px ;
    margin-top:33px ;
}
.text .btn{
    width: 190px;
    height: 49px;
    left: 388px;
    top: 568px;
    
    background: #01ACBE;
    border-radius: 4px;
    font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #FFFFFF;
}
/* .img{
    position: absolute;
width: 425px;
height: 540px;
left: 1136px;
top: 736px;

background: rgba(0, 174, 191, 0.6);
transform: rotate(-180deg);
} */

.modal-img 
{
    background-color: #763CFF;
}
.modal-footers {
    background-color: #763CFF;
    padding: 17px 50px 15px;
    align-items: center;
    display: flex;
    position: relative;
}
.modal-footers::before {
    content: '';
    border-top: 1px solid rgba(255, 255, 255, 0.39);
    left: 25px;
    right: 25px;
    position: absolute;
    top: 0;
}
.footer-left {
    flex: 1;
    padding-right: 10px;
}
.modal-footers label 
{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;

    color: #FFFFFF;
}
.modal-footers h4
{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    margin: 0;

    color: #FFFFFF;
}
.footer-right {
    margin-left: auto;
}
.modal-footers a 
{
    margin-bottom: 4px;
    color: #fff;
    font-family: 'Open Sans';
    width: 190px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 49px;
    text-align: center;
    background: #01ACBE;
    border-radius: 4px;
    border: 1px solid #01ACBE !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}
.modal-footers a:hover
{
    border-color: #fff !important;
    background-color: #fff;
    color: #01ACBE;
}
.topcheckbox {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    display: flex;
    align-items: center;
}
.topcheckbox input 
{
    margin-right: 10px;
    background: rgba(255, 255, 255, 0.48);
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    height: 16px;
    width: 16px;

}
.close-btn
{
    background-color: transparent;
    position: absolute;
    right: 16px;
    border:none;
    top:16px;
}
@media screen and (max-width:767px)
{
    .modal-footers button
    {
        width: 115px;
        height: 42px;
    }
    .modal-footers
    {
        padding: 17px 20px 15px;
    }
    .modal-footers::before
    {
        left: 20px;
        right: 20px;
    }
    .modal-footers h4
    {
        font-size: 16px;
    }
    .modal-footers label
    {
        font-size: 14px;
        margin: 0;
        line-height: 20px;
    }
    .topcheckbox
    {
        top:30px;
        font-size: 13px;
    }
    .close-btn
    {
        top:30px;
    }
}

/* .smartBreakModal .modal .show .modal-dialog{
    top: 8%;
} */
.handlemodal{
    top: 8% !important;
}

.logout-button
{
    left: 400px;
    width: 50px;
    position: relative;
    top: 2px;
    color: red;
    cursor: pointer;
}
.topcheckbox input{
    cursor: pointer;
}
.GoogleLogin-component{
    visibility: hidden !important;
    height: 0px !important;
}