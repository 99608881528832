//
// Content
//

// Desktop Mode
@include media-breakpoint-up(lg) {
	.content {
		padding: get($page-padding, desktop) 0;

		// Fixed Footer Mode
		.footer-fixed & {
			padding-bottom: get($footer-config, fixed, height);
		}
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	.content {
		padding: get($page-padding, tablet-and-mobile) 0;

		// Subheader Enabled & Subheader Transparent Modes
		.subheader-enabled.subheader-transparent & {
			padding-top: 0;
		}
	}
}


.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: #fff !important;
    border:none;
  }
  

  .e-calendar .e-content td.e-today.e-selected span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day{
	background-color:#008e9d !important;
	border:1px solid #008e9d !important;
	color: #fff !important;

  }

  .e-calendar .e-content td.e-selected span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day{
	background-color:#008e9d !important;
	border:1px solid #008e9d !important;
	
  }
  .e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary{
	  color:#008e9d !important;
  }

  .e-calendar .e-content td.e-today span.e-day, .e-calendar .e-content td.e-focused-date.e-today span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-today span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day{
	border:1px solid #008e9d !important;
	color: black !important;
  }
  input.e-control.e-daterangepicker.e-lib.e-input.e-keyboard::selection {
    background: #008e9d !important;
}

.button-highlighted{
background: #008290 !important;
}

.button-back-highlighted{
	color:#008e9d !important;
}