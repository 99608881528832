// Initialization of global variables, mixins and functions
@import "../../../init";

.kt-splash-screen {
    position: fixed !important;
    top: 0;
    width: 100%;
    left: 0;
}
.apexcharts-tooltip {
    display: inline-block;
    white-space: pre-wrap !important;
    max-width: 200px;
  }

@media (min-width: 992px) {
    .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
        padding: 0 36px !important
    }
}

body {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
}
::-webkit-scrollbar {
    width: 7px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #F8F9FB; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8FE0E9; 
    border-radius: 10px;
    cursor: pointer; 
}
*:hover::-webkit-scrollbar-thumb {
    background: #8FE0E9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #4ACBDA;
    cursor: pointer; 
}

.btn-light-primary {
    background-color: #D3F5F9;
    border: 1px solid #00ACBF;
}

.auth-container {
    min-height: 100vh;
    background-color: #fff; 
    &.sign-in-pg {
        .left_col {
            @media (max-width: 1290px) {
                padding: 50px 35px;
            }
            @media (max-width: 768px) {
                padding: 30px 20px;
            } 
            .auth_section {
                justify-content: space-between;
                .auth_content {
                    margin: 70px 0;
                    @media (max-width: 768px) {
                        margin: 50px 0;
                    } 
                }
            }
        }
    }
    .left_col {
        padding: 80px 65px; 
        @media (max-width: 1920px) {
            flex: 0 0 45%;
            max-width: 45%;
        }
        @media (max-width: 1439px) {              
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media (max-width: 1366px) {           
            padding: 35px;
        }
        @media (max-width: 1200px) {           
            padding: 25px;
        }
        @media (max-width: 768px) {           
            padding: 15px;                              
            flex: 0 0 100%;
            max-width: 100%; 
        }
        .auth_section {
            height: 100%;
            display: flex;
            flex-direction: column;            
            .auth_steps {
                display: flex;
                margin-bottom: 60px;
                div {
                    height: 4px;
                    background: #DFE2E6;
                    border-radius: 3px;
                    margin-right: 6px;
                    text-indent: -9999px;
                    flex: 1 0 auto;
                    &:last-child {
                        margin-right: 0;
                    }
                    &.active {
                        background: #8FE0E9;
                    }
                    &.completed {
                        background: #00ACBF;
                    }
                }
            }
            .auth_logo {
                display: inline-block;
            }
            .auth_content {
                margin: 70px 0 0; 
                @media (max-width: 768px) {
                    margin: 50px 0 0;
                }              
                .auth_title {
                    font-size: 28px;
                    font-weight: bold;
                    color: #0A2129;
                    margin-bottom: 28px;
                    span {
                        color: #00ACBF;
                    }
                }
                p {
                    font-size: 14px;
                }
                .auth_buttons {
                    text-align: center;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    margin-top: 60px;
                    position: relative;
                    .google-button {    
                        font-size: 14px !important;
                        padding: 5px !important;
                        min-width: 222px !important;
                        background: #F2F4F7 !important;
                        border-radius: 4px !important;
                        color: #343B43 !important;
                        margin-bottom: 16px !important;
                        text-align: left !important;
                        box-shadow: none !important;
                        opacity: 1 !important;
                        > div {
                            background-color: transparent !important;
                            border-right: solid 1px #E4E8ED;
                            margin-right: 14px!important;
                            padding: 10px 15px!important;
                            border-radius: 2px;
                        }
                        span {
                            font-weight: 400 !important;
                        }
                    }
                    .btn-container {
                        margin-left: 10px;
                        border-radius: 4px;
                        min-width: 222px;
                        overflow: hidden;
                        position: relative;
                        height: 53px;
                        @media (max-width: 1115px) {
                            margin-left: 0;
                        }
                        @media (max-width: 768px) {
                            margin-left: 10px;
                        }
                        @media (max-width: 495px) {
                            margin-left: 0;
                        }
                        svg {
                            position: relative;
                            z-index: 1;
                            opacity: 0;
                            path {
                                fill: #fff;
                            }
                        }
                        &:hover 
                        {
                            .btn-auth
                            {
                                background: #D3F5F9 !important;
                                border-color: #00acbf !important;
                            }  
                        }
                        .btn-auth {
                            position: absolute;
                            top: 0;
                            left: 0;
                            pointer-events: none;
                            z-index: 2;
                            background: #F2F4F7;
                            border-radius: 4px;
                            color: #343B43;
                            font-size: 14px;
                            padding: 13px;
                            min-width: 222px;
                            margin-bottom: 16px;
                            text-align: left;
                            img {
                                border-right: solid 1px #E4E8ED;
                                padding-right: 13px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
                .auth_form {
                    .auth-slider {
                        width: 100%;
                        padding: 0 10px;
                        > div {
                            width: 100%;
                        }
                        .MuiSlider-colorPrimary {
                            .MuiSlider-rail {
                                background: linear-gradient(90deg, #D3F5F9 0.32%, #00ACBF 49.99%, #02444C 100%);
                                opacity: 1;
                                height: 3px;
                                border-radius: 3px;
                            }
                            .MuiSlider-track {
                                background-color: transparent;
                            }
                            .MuiSlider-mark {
                                background-color: #ccc;
                                height: 8px;
                                top: 23px;
                            }
                            .MuiSlider-markLabel {
                                background-color: transparent;
                                top: 38px;
                                img {
                                    display: block;
                                }
                                &.MuiSlider-markLabelActive {
                                    background-color: transparent;
                                    z-index: 2;
                                    img {
                                        display: block;
                                    }
                                }
                            }
                            .MuiSlider-thumb {
                                background-color: transparent;
                                margin-top: -11px;
                                border-radius: 0;
                                width: 0;
                                height: 0;
                                border-left: 7px solid transparent;
                                border-right: 7px solid transparent;
                                border-top: 8px solid $primary;
                                > span {
                                    top: -35px;
                                    > span {
                                        background-color: transparent;
                                        > span {
                                            color: #737980;
                                            font-size: 12px;
                                        }
                                    }
                                }
                                .MuiSlider-valueLabel {
                                    left: calc(-50% - 15px);
                                }
                            }
                            .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
                                box-shadow: none
                            }
                        }
                    } 
                    
                    .input-group {
                        .input-group-prepend {
                            .btn {
                                background-color: #f8f9fb;
                                border: solid 1px #DFE2E6;
                            }
                        }
                    }
                    
                    .btn-next {
                        font-size: 16px;
                        color: $white;
                        line-height: 100%;
                        font-weight: $font-weight-bold;
                        padding: 17px 50px;
                        background-color: $primary;
                        &:hover {
                            background-color: $primary-hover;
                        }
                    }
                    .btn-back {
                        font-size: 16px;
                        color: #999EA5;
                        line-height: 100%;
                        font-weight: $font-weight-bold;
                        padding: 17px 0px;
                        background-color: transparent;
                        &:hover {
                            background-color: transparent;
                            color: #5a6068;
                        }
                    }
                }
            }            
            .auth_note {
                display: flex;
                align-items: flex-start;
                color: #464E57;
                font-size: 14px;
                line-height: 150%;
                background: #F2F4F7;
                border: 1px solid #E4E8ED;
                padding: 20px;
                border-radius: 4px;
                img {
                    flex: 0 0 22px;
                    margin-right: 10px
                }
            }
        }        
    }
    .right_col {
        background: #D3F5F9;
        padding: 120px 8% 0;
        border-radius: 40px 0px 0px 40px; 
        @media (max-width: 1920px) {
            flex: 0 0 55%;
            max-width: 55%;
        }
        @media (max-width: 1439px) {              
            flex: 0 0 50%;
            max-width: 50%;
        }  
        @media (max-width: 1200px) {           
            padding: 5% 25px;
        }
        @media (max-width: 768px) {               
            flex: 0 0 100%;
            max-width: 100%;         
            padding: 35px 20px;
            border-radius: 40px 40px 0px 0px; 
        }
        @media (max-width: 414px) {           
            padding: 25px 15px;
        }
        .step_view {
            display: flex;
            flex-direction: column;
            height: 100%;          
            h1 {
                text-align: center;
                color: #02444C;
                font-size: 28px;
                line-height: 140%;
            }
            .step_view-img {
                flex: 0 1 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.auth-selectgroup {
    display: inline-flex; 
    flex-wrap: wrap;
    .auth-selectgroup-item {
        position: relative;
        font-weight: 400 !important;
        margin-bottom: .5rem;
        color: #495057;
        white-space: nowrap;
        margin-right: 12px;
        .selectgroup-input {
            opacity: 0;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            &:checked  {
                & + .selectgroup-button {
                    border-color: #00ACBF;
                    z-index: 1;
                    color: #00ACBF;
                    background: #D3F5F9;
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }                            
        @media (max-width: 414px) {
            flex-grow: 0;
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            } 
        } 
        .selectgroup-button {
            display: block;
            border: 1px solid #E4EAF0;
            text-align: center;
            padding: 8px 16px;
            position: relative;
            cursor: pointer;
            border-radius: 3px;
            color: #8492A5;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            font-size: 14px;
            line-height: 1.5rem;
            min-width: 40px;
            text-transform: capitalize;
            letter-spacing: 0;
        }
    }
}



/* ----- MS-WRAPPER -----*/
.header-fixed.subheader-fixed.subheader-enabled {    
    .wrapper {
        padding-top: 80px !important;
        padding-left: 252px;
        @media (max-width: 991.98px) {
            padding-top: 80px !important;
            padding-left: 0;
        }
    }
}

.aside-minimize .ms--wrapper .wrapper .header {
    left: 70px !important;
}

#kt_header_mobile {
    .burger-icon {   
        margin-right: 12px;
        span {
            background-color: #00acbf;
            &:after, &:before {
                background-color: #00acbf;
            }
        }
    }
    #kt_header_mobile_topbar_toggle {
        .svg-icon svg g [fill] {
            fill: #00acbf
        }
    }
}
.ms--wrapper {   
    background-color: #F8F9FB;
    /* ----- MS-HEADER -----*/
    .wrapper {
        .header-fixed {
            height: 80px;
            left: 252px !important;
            @media (max-width: 768px) {
                height: auto;    
                padding-bottom: 15px;

            }
            & .aside-minimize {                
                left: 70px !important;
                .header {
                    left: 70px !important;
                }
            }
            > .container-fluid {
                padding-left: 30px !important;
                padding-right: 30px !important;
                @media (max-width: 768px) {
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }
            }
        }
        .content {
            padding-top: 0;
        }
    }
    .ms-header {        
        background-color: #f8f9fb;
        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
        .ms-header--title {
            @media (max-width: 768px) {
                flex: 0 0 100%;
                margin-bottom: 15px;
            }
            .header--title {
                font-size: 24px;
                color: #0A2129;
                font-weight: bold;
                margin: 0;
            }
        }
        .ms-header--timeperiod {
            @media screen and (max-width:460px)
            {
                flex-wrap: wrap;
            }
            .header-filter-icn {
                background-color: #E4E8ED;
                border-radius: 4px;
                padding: 9px 5px;
                display: flex;
                align-items: center;
                margin-right: 28px;
                @media (max-width: 768px) {
                    margin-right: 12px;
                }
                .fas {
                    font-size: 11px;
                    color: #999EA5;
                    margin: 0 2px;
                    &.active {
                        color: #273443;
                    }
                }
            }
            div {
                &:last-child {
                    margin-right: 0 !important;
                }
            }
            p {
                color: #0A2129;
                font-weight: bold;
                @media (max-width: 1200px) {
                    line-height: 15px;
                }
                @media (max-width: 768px) {
                    line-height: 13px;
                }
                @media (max-width: 460px) {
                    width: 100%;
                    margin-bottom: 10px !important;
                }
                
            }
            .ms-month {
                margin-left: 16px;
                @media screen and (max-width:460px)
                {
                    width: 115px;
                    margin-left: 0;
                }
                .form-control {
                    background-color: #E4E8ED;
                    border-color: #E4E8ED; 
                    padding-top: 3px;
                    padding-bottom: 4px;
                    max-height: 32px;                  
                }
                .btn {
                    background-color: #E4E8ED;
                    color: #0A2129;
                }
            }
            .ms-date {
                margin-left: 16px;
                @media screen and (max-width:460px)
                {
                    flex: 1;
                }
                .form-control {                
                    background-color: #E4E8ED;
                    border-color: #E4E8ED;
                }                
                .e-control-wrapper {
                    border: solid 1px #CCCCCC !important;
                    border-radius: 4px !important;
                    margin: 0 !important;
                    min-width: 207px !important;
                    padding-left: 13px !important;
                    background-color: #E4E8ED !important;
                    @media (max-width: 560px) {
                        min-width: auto !important;
                    }
                    &:after, &:before {
                        display: none;
                    }
                    .e-input-group-icon {
                        &.e-active {
                            color: $primary !important;
                        }
                    }
                }
            }
        }
    }

    /*---- SIDE NAVIGATION LIST ----*/
    .aside { 
        width: 252px;  
        .brand {
            position: relative;
            justify-content: center !important;
            height: auto;
            padding: 0 10px;
            .brand-logo {
                display: block;
                width: 100%;
                text-align: center;
                border-bottom: solid 1px #eee;
                padding: 8px 0 !important;
                margin-bottom: 8px;
                .logo-small {
                    display: none;;
                }
                // Minimized Aside Mode
                .aside-minimize:not(.aside-minimize-hover) & {
                    .logo-full {
                        display: none;
                    }
                    .logo-small {
                        display: block;
                    }
                }
            }
            .brand-toggle {
                position: absolute;
                right: -6px;
                background-color: #DFE2E6;
                bottom: -35px;
                width: 14px;
                height: 24px;
                border-radius: 2px;
                span {
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        left: -1px;
                        top: -2px;
                        border-top: 5px solid transparent;
                        border-right: 6px solid #737980;
                        border-bottom: 5px solid transparent;
                        transform: rotateX(0);
                        transition: all ease .3s;
                    }
                }
            }
        }     
        .aside-menu-wrapper {
            display: flex;
            flex-direction: column;
            padding: 12px;
            height: 100vh;
            .ms_aside-menu {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                overflow-y: auto;
                max-height: calc(100vh - 193px);                
                @media (max-width: 991.98px) {
                    max-height: calc(100vh); 
                }
                .nav-header {
                    color: #999EA5;
                    font-size: 10px;
                    letter-spacing: 0.08em;
                    text-transform: uppercase;
                    padding-left: 20px;
                    margin-top: 25px;
                    transition: all ease .3s;
                }
            }            
            .nav_footer {
                .faq-link {
                    font-size: 14px;
                    a {
                        display: flex;
                        width: 100%;
                        font-weight: bold;
                        align-items: center;
                        font-size: 14px !important;
                        color: #0A2129 !important;
                        padding: 9px 20px;
                        img {
                            margin-right: 20px;               
                            @media (max-width: 768px) {
                                margin-right: 0;
                                margin: 0 auto;
                            }
                        }
                    }
                }
                .user_profile_link {
                    border-top: solid 1px #E4E8ED;
                    .btn-profile-nav {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 4px 14px;
                        margin-top: 4px;
                        .profile-user-img {
                            width: 32px;
                            height: 32px;
                            margin-right: 10px;
                            border: solid 1px #fff;
                        }
                        &:after {
                            transform: rotateX(180deg);
                            position: absolute;
                            right: 12px;
                            top: 22px;
                            color: #00ACBF;
                            font-size: 9px;
                        }
                        span {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            padding-right: 11px;
                        }
                    }
                    &.show {
                        .btn-profile-nav {
                            background: #008E9D;
                            color: #fff;
                            &:after {
                                transform: rotateX(0);
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
.aside-fixed {
    .aside {
        box-shadow: 0px 3px 9px rgba(181, 189, 198, 0.14);
    }
}
.footer {
    &.d-none {
        display: none !important;
    }
}
.ms_aside-menu {
    .menu-nav {            
        & > .menu-item {                
            & > .menu-link {
                padding: 9px 20px !important;
                transition: all ease .3s;
                border-radius: 4px;
                border: none;
                background-color: transparent;
                .menu-text {
                    font-size: 14px !important;
                    color: #0A2129;
                    transition: all ease .3s;
                    .no {
                        background-color: #0A2129;
                        color: #fff;
                        font-weight: bold;
                        padding: 2px 4px 1px;
                        line-height: 12px;
                        margin-left: 6px;
                        border-radius: 3px;
                        display: inline-block;
                        font-size: 10px;                     
                    }
                }
                &.active {
                    .menu-text {
                        .no {
                            background-color: #fff;
                            color: #0A2129;                    
                        }
                    }
                }
                .svg-icon {
                    svg {
                        width: 16px !important;
                        height: auto;
                        &.default-icn {
                            display: inline-block;
                        }
                        &.hover-icn {
                            display: none;
                        }
                    }
                }                
                &.active {
                    .menu-text {
                        color: #fff !important;
                    }                    
                    .svg-icon {
                        svg {
                            &.default-icn {
                                display: none;
                            }
                            &.hover-icn {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
            &:hover {
                > .menu-link {
                    .menu-text {
                        color: #fff !important;
                    }                    
                    .svg-icon {
                        svg {
                            &.default-icn {
                                display: none;
                            }
                            &.hover-icn {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
    & .menu--reports {
        & > .menu-item {                
            & > .menu-link {            
                .menu-text {
                    font-size: 12px !important;
                }
            }
        }  
    }
}
.metting--duration {
    border: 1px solid #E4E8ED;
    border-radius: 4px;
    padding: 15px 16px;
    .title {
        color: #999EA5;
        font-size: 11px;
        margin-bottom: 2px;
    }
    .metting--duration_body {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 145px;
        .metting--duration-label {
            display: flex;
            align-items: center;
            font-size: 10px;
            margin-top: 15px;
            .label_color {
                display: block;
                width: 11px;
                height: 11px;
                border-radius: 2px;
                margin-right: 8px;
                &.clr-1 {
                    background-color: #D3F5F9;
                }
                &.clr-2 {
                    background-color: #8FE0E9;
                }
                &.clr-3 {
                    background-color: #4ACBDA;
                }
                &.clr-4 {
                    background-color: #00ACBF;
                }
                &.clr-5 {
                    background-color: #008E9D;
                }
                &.clr-6 {
                    background-color: #00626C;
                }
                &.clr-7 {
                    background-color: #02444C;
                }
            }
        }
    }

}

.aside-minimize {
    .aside {        
        .brand {
            .brand-logo {
                padding: 18px 0 !important;
            }
            .brand-toggle {
                span {
                    &:after {
                        transform: rotateY(180deg) !important;
                    }
                }
            }
        }        
        .aside-menu-wrapper {   
            padding: 12px 7px;     
            .ms_aside-menu {
                max-height: calc(100vh - 192px);
                .menu-nav {            
                    & > .menu-item {                
                        & > .menu-link {
                            padding: 9px 12px !important;
                            justify-content: center;
                            .menu-icon {
                                flex: 0 0 16px;
                            }
                        }
                    }
                }
                .nav-header {
                    font-size: 9px !important;
                    letter-spacing: 0.4px !important;
                    padding-left: 0 !important;
                    text-align: center;
                    margin-top: 0;
                }
                .metting--duration {
                    padding: 16px 3px;
                    max-height: 239px;
                    overflow-y: auto;
                    min-height: 200px;
                    .title {
                        text-align: center;
                    }
                    .metting--duration_body {
                        flex-wrap: nowrap;
                        max-height: 100%;
                        .metting--duration-label {
                            font-size: 10px;
                            flex-direction: column;
                            margin-top: 9px;
                            text-align: center;
                            white-space: normal;
                            flex-wrap: wrap;
                            .label_color {
                                display: block;
                                width: 11px;
                                height: 11px;
                                border-radius: 2px;
                                margin-right: 0;
                            }
                        }
                    }
                }


            }
            .nav_footer {
                .faq-link {
                    a {
                        padding: 9px 12px !important;
                        span {
                            display: none;
                        }
                        img {
                            margin: 0 auto;
                        }
                    }
                }
                .user_profile_link {
                    .btn-profile-nav {
                        padding-left: 2px;
                        span {
                            display: none;
                        }
                        &:after {
                            right: 7px;
                        }
                    }
                }
            }
        }
    }
}
.aside-on {
    .nav_footer {
        .faq-link {
            a {     
                @media (max-width: 768px) {                            
                    img {
                        margin: 0 20px 0 0 !important;
                    }
                }
            }
        }
    }
}

/* ---- DASHBOAD CARDS ----*/

.col-card-gutter {
    > [class*='col-'] {
        padding-left: 12.5px;
        padding-right: 12.5px;
    }
}
.col--innercard-gutter {
    margin-right: -8.5px !important;
    margin-left: -8.5px !important;
    > [class*='col-'] {
        padding-left: 8.5px;
        padding-right: 8.5px;
    }
}

.card-body {
    + .card-header {
        padding-top: 5px !important
    }
}
.card-title-subheading {
    color: #999EA5;
    font-size: 12px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 0;
    &.font-weight-normal {
        font-size: 14px;
        color: #737980;
        strong {
            color: #0A2129;
        }
    }
}

.your-badge--row {
    margin-right: -8.5px !important;
    margin-left: -8.5px !important;
    flex-wrap: wrap;
    justify-content: space-between;
    .your-badges--col {
        padding-left: 8.5px;
        padding-right: 8.5px;
        flex: 0 0 30%;
        max-width: 30%;
        @media (max-width: 1280px) {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }
        @media (max-width: 991px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .your-badges--heading {
            font-weight: bold;
        }
    }
    .your-badges--col2 {
        padding-left: 8.5px;
        padding-right: 8.5px;
        flex: 0 0 100%;
        max-width: 100%;
        @media (max-width: 1280px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @media (max-width: 991px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .your-badges--heading {
            font-weight: bold;
        }
    }
}

.card {
    &.card-custom {
        height: calc(100% - 24px);
        .card-header {
            .card-title {
                span {
                    &:nth-child(2) {
                        font-size: 11px;
                        font-weight: 400;
                        color: #737980;
                        margin-top: 0;
                    }
                }
            }
            .card-subtitle {
                flex: 0 0 100%;
                margin-top: 0;
                justify-content: space-between;
                align-items: center;
                h5 {
                    font-size: 12px;
                    color: #737980;
                    font-weight: 400;
                    margin-top: 8px;
                }
                .card-subtitle-btn {
                    min-width: 30%;
                    display: flex;
                    justify-content: flex-end;
                }
            }            
            .btn {
                line-height: 15px;
                margin-left: 10px;
                padding: 5px 10px;
                font-size: 12px;
                border-radius: 3px;
                &.btn-light {
                    border: 1px solid #E4E8ED;
                    background-color: white;
                    color: #999EA5;
                }
                &.btn-light-primary {
                    background: #D3F5F9;
                    color: #00ACBF;
                    border: solid 1px $primary;
                }
            }
        }
        > .card-body {
            @media (max-width: 768px) {
                padding: 7px 10px 16px;
            }
        }
    }
}

.api--card {
    border: 1px solid #E4E8ED;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px 20px;
    margin: 10px 0 5px;
    min-height: calc(100% - 16px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1366px) {
        padding: 16px 10px;
    }
    @media (max-width: 414px) {
        padding: 12px;
    }
    .api--card_title {
        font-size: 12px;
        color: #999EA5;
        margin-bottom: 12px;
        font-weight: 500;
    }
    .api--detail {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        .detail-number {
            font-size: 32px;
            font-weight: bold;
            color: #0A2129;
            line-height: 100%;
            @media (max-width: 1600px) {
                font-size: 30px;
                letter-spacing: -1px;
            }
            @media (max-width: 1366px) {
                font-size: 22px;
            }
            @media (max-width: 1200px) {
                font-size: 18px;
            }
            @media (max-width: 991px) {
                font-size: 22px;
            }
            @media (max-width: 414px) {
                font-size: 17px;
            }
        }
        .detail-percentage {
            color: #46BB3B;
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 14px;
            &.down {
                color: #E34F4F;
            }
            img {
                margin-left: 3px;
            }
            @media (max-width: 1600px) {
                letter-spacing: -1px;
            }
            @media (max-width: 1366px) {
                font-size: 13px;
            }
            @media (max-width: 414px) {
                font-size: 11px;
            }
        }
        .red{
            color:#E34F4F;
        }
        .green{
            color:#46BB3B
        }
    }
    &.d-flex {
        .api--card_chart {
            flex: 0 0 75px;
            max-width: 75px;
        }
    }
}

.color-label-wrap {
    display: flex;
    align-items: center;
    height: calc(100% - 30px);
    .color-label {
        display: flex;
        align-items: center;
        font-size: 10px;
        &.circle {
            .color_box {
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background-color: transparent !important;
                border: solid 2px #00ACBF;
            }
        }
        .color_box {
            display: block;
            width: 11px;
            height: 11px;
            border-radius: 2px;
            margin-right: 8px;
            &.primary {
                background-color: #00626C;
            }
            &.primary-light {
                background-color: #4ACBDA;
            }
            &.dark {
                background-color: #0A2129;
            }
        }
        + .color-label {
            margin-left: 12px;
        }
    }
}

.achieve--card {
    border: 1px solid #E4E8ED;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px 20px;
    margin: 10px 0 5px;
    min-height: calc(100% - 16px);
    align-items: center;
    display: flex;
    justify-content: space-between;
    .achieve--icon {
        flex: 0 0 60px;
    }
    .achieve--card_body { 
        flex-grow: 1;       
        .achieve--card_title {
            color: #0A2129;
            margin-bottom: 9px;
            font-weight: 500;
        }
        .achieve--card_content {
            color: #737980;
            strong {
                color: #333;
            }
        }
    }
}


.autocomplete-container .hide {
    display: none;
}

.autocomplete-container .show {
    display: block;
}

.country-code-input {
    background: #F8F9FB !important;
    padding: 26px 0px 24px 50px !important;
    background-color: #F8F9FB !important;
    border-color: #DFE2E6 !important;
    color: #343B43 !important;
    font-size: 14px !important;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.selected-flag {
    background: #F8F9FB !important;
    border-color: #DFE2E6 !important;
    color: #343B43 !important;
    font-size: 14px !important;
}

.alert.custom-alert {
    background-color: #ffffff ;
    color: #000;
    width: 362px;
    height: auto;
    text-align: center;
    font-size: 14px;;
    border:none;
    z-index: 2;
    position: absolute;
    top: 35%;
    left:32%;
    padding: 40px;
}

.alert-icon i {
    font-size: 3.25rem;
}



.feedback--list {
    &.table {
        td {
            padding-left: 0;
            padding-right: 0; 
            &.value {
                color: #0A2129;
                font-weight: 700;
                font-size: 20px;
                line-height: 16px;
                text-align: right;
            }
        }
    }
}
.rate--buttons {
    .rate--buttons_table {
        margin-top: 7px;
        padding-top: 15px;
        border-top: solid 1px #E4E8ED;
        border-bottom: solid 1px #E4E8ED;   
        table {
            width: 100%;
            margin-bottom: 1rem;
            background-color: transparent;
            td {
                padding-left: 0;
                padding-right: 0;
                padding-top: 5px;
                padding-bottom: 5px; 
                &.rate--btns {
                    text-align: right;
                    .auth-selectgroup {
                        flex-wrap: nowrap !important;                          
                        .auth-selectgroup-item {
                            margin-bottom: 2px;                                                 
                            .selectgroup-button {
                                padding: 4px 11px !important;
                                border: 1px solid #E4E8ED;
                                font-weight: 500;
                                color: #0A2129;
                                background-color: #E4E8ED;
                                &.btn--no {
                                    background-color: #F8F9FB;
                                    &:hover {
                                        background-color: #f3f4f7;
                                    }
                                }
                                &.btn--yes {
                                    background-color: #F2F4F7;
                                    &:hover {
                                        background-color: #e6e9ee;
                                    }
                                }
                            } 
                            .selectgroup-input {                        
                                &:checked  {
                                    & + .selectgroup-button {
                                        border-color: #4ACBDA !important;
                                        z-index: 1;
                                        color: #fff !important;
                                        background-color: #8FE0E9 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } 
    }
    .card-footer {
        padding: 24px 0 10px;
        border: none;
        position: relative;
        .unrated-error {
            position: absolute;
            left: 0;
            text-align: center;
            top: -12px;
            width: 100%;
            .unrated-error-msg {
                color: #E34F4F;
                font-weight: 500;
                font-size: 11px;
                background-color: #fff;
                display: inline-block;
                padding: 2px 9px;
            }
        }
        .btn {
            width: 50%;        
            &.btn--ddnt-attend {
                color: $primary;
                border: 1px $primary solid;
                margin-right: 10px;
                &:hover {
                    background-color: $primary;
                    color: #fff;
                }
            }
            &.btn--submit {
                background-color: $primary;
                color: #fff;
                margin-left: 10px;
                &:hover {
                    background-color: $primary-hover;
                    color: #fff;
                }
            }
        }
    }
}
.expensive--meetings {    
    &.table {
        td {
            padding-left: 0;
            padding-right: 0;
            &.value {
                color: #00ACBF;
                font-weight: 700;
                font-size: 20px;
                line-height: 16px;
                text-align: right;
                span {
                    color: #D3F5F9;
                }                     
                p {
                    display: inline-block;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 16px;
                    background: #00ACBF;
                    background: -webkit-linear-gradient(to right, #00ACBF 50%, #D3F5F9 50%);
                    background: -moz-linear-gradient(to right, #00ACBF 50%, #D3F5F9 50%);
                    background: linear-gradient(to right, #00ACBF 50%, #D3F5F9 50%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        th {
            padding-left: 0;
            padding-right: 0; 
            &:last-child {
                text-align: right;
            }
        }
    }
}

.badge--card {
    .badge--card-title {
        font-size: 12px;
        color: #737980;
        margin-bottom: 7px;
    }
    .badge--card-body {
        .label {
            padding: 7px 12px !important;
            font-size: 11px;
            font-weight: 500;
            border: solid 1px transparent;
            margin-bottom: 3px;
            &.label-light-primary {
                background-color: #D3F5F9;
                color: #00ACBF;
                border-color: #D3F5F9;
            }
            &.label-light-primary2 {
                background-color: #4acbda66;
                color: #00ACBF;
                border-radius: 50%;
                border-color: #D3F5F9;
            }
            &.label-light-danger {
                background-color: #FCEDED;
                color: #E34F4F;
                border-color: #FCEDED;
            }
            &.label-light {
                background-color: #fff;
                border-color: #E4E8ED
            }
            &:last-child {
                margin-right: 0 !important;
            }
        }
    }
}

/* ---- USER PROFILE ----*/

.profile-container {
    .nav-container {
        position: relative;
        .nav-scrollable {  
            overflow-x: auto;    
            .nav {
                @media (max-width: 768px) {
                    flex-wrap: nowrap;
                } 
                .nav-item {
                    .nav-link {
                        font-size: 16px;
                        color: #999EA5;
                        border-bottom: solid 4px transparent;
                        @media (max-width: 768px) {
                            white-space: nowrap;
                            font-size: 14px;
                            padding: 10px;
                            border-bottom: transparent;
                            border-top: solid 4px transparent;
                        }                        
                        &.active {
                            color: #343B43;
                            font-weight: 500;
                            border-color: #00ACBF;
                        }
                    }
                }
            }
        } 
        .edit-button {
            .btn {
                &.btn-primary {
                    padding: 8px 20px;
                    &:hover {
                        background-color: $primary-hover;
                    }
                }
                &.btn-edit {
                    padding: 8px 43px;
                    background-color: transparent;
                    border-color: $primary;
                    color: $primary;
                    &:hover {
                        background-color: $primary;
                        color: #fff;
                    }
                }
            }
            @media (max-width: 768px) {
                position: absolute;
                right: 0px;
                bottom: 74px;
                z-index: 1;
                
            }
        }
    }
    .tab-content {
        .card-custom {
            .card-body {
                .tab-pane {
                    padding: 30px 0;
                    .user-personal-info {
                        &.info-view {
                            .user--pic {
                                img {
                                    width: 60px;
                                    height: 60px;
                                }
                            }
                            .user--info {
                                cursor: default !important;
                            }
                        }
                        &.info-edit {
                            .user-pic--name {
                                .add-pic {
                                    flex: 0 0 40%;
                                    display: flex;
                                    flex-direction: column;
                                    .profilePic-wrap {
                                        height: 152px;
                                        display: flex;
                                        justify-content: center;   
                                        position: relative;                             
                                        img {
                                            display: block;
                                            max-width:152px;
                                            width: 152px;
                                            object-fit: cover;
                                            height: 152px;
                                        }
                                        .upload-btn {
                                            position: absolute;
                                            overflow: hidden;
                                            display: inline-block;
                                            height: 100%;
                                            width: 100%;
                                            .btn {
                                                color: #00ACBF;
                                                font-weight: 500;
                                                height: 1px;
                                                font-size: .001rem;
                                                text-indent: -9999px;
                                            }
                                            input {
                                                &[type=file] {
                                                    font-size: 100px;
                                                    position: absolute;
                                                    left: 0;
                                                    top: 0;
                                                    opacity: 0;
                                                }
                                            }
                                        }
                                    }
                                    .upload-btn {
                                        position: relative;
                                        overflow: hidden;
                                        display: inline-block;
                                        .btn {
                                            color: #fff;
                                            padding: 8px 20px;
                                            font-weight: 500;
                                            margin-top: 20px;
                                            background: #00ACBF;
                                            border: 1px solid #00acbf;
                                            &:hover 
                                            {
                                                background-color: transparent;
                                                color: #00ACBF
                                            }
                                        
                                        }
                                        input {
                                            &[type=file] {
                                                font-size: 100px;
                                                position: absolute;
                                                left: 0;
                                                top: 0;
                                                opacity: 0;
                                            }
                                        }
                                    }                                    
                                    @media (max-width: 768px) {                                        
                                        flex: 0 0 100%;
                                    }
                                }                                                                    
                                @media (max-width: 768px) {                                        
                                    flex-wrap: wrap;
                                }
                                .user-name-wrap {
                                    @media (max-width: 768px) {                                        
                                        margin-left: 0 !important;
                                    } 
                                }
                            }
                        }
                        .col-lg-6 {
                            &:first-child {
                                padding-right: 30px;
                                border-right: solid 1px #E4E8ED;   
                                @media (max-width: 768px) {
                                    border: none;
                                    padding-right: 15px;
                                }
                            }
                            &:last-child {
                                padding-left: 30px;  
                                @media (max-width: 768px) {
                                    padding-left: 12px;
                                    margin-top: 31px;
                                }
                            }
                        }
                    }                    
                    
                }
            }
        }
    }
}

.error {
    .form-control {
        border-color: #E34F4F !important;
        background: #F8F9FB;
    }
    .errorValidationMessage {
        color: #E34F4F;
        font-size: 12px;
    }
}

.Notifications-wrap {
    .card {
        border: 1px solid #E4E8ED;
        background-color: #F8F9FB;
        height: 100%;
        .card-body {
            padding: 20px;
            h5 {
                font-weight: 500;
                font-size: 16px;
                color: #008E9D;
                margin-bottom: 17px;
            }
            .auth-selectgroup {
                .auth-selectgroup-item {
                    margin-right: 11px;
                    
                    .selectgroup-button {
                        padding: 8px 14px;
                        @media (max-width: 1366px) {
                            padding: 8px 10px;
                        }
                        @media (max-width: 767px)
                        {
                            padding: 8px 6px;
                            font-size: 13px;
                        }
                    }
                    @media (max-width: 1366px) {
                        margin-right: 10px;
                    }
                    @media (max-width: 767px)
                    {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}
.privacy-wrap {
    .privacy--points {
        padding: 0;
        margin: 17px 0 20px 20px;
        list-style: none;
        li {
            position: relative;
            padding-left: 21px;
            margin-bottom: 10px;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 6px;
                background-color: $primary;
                border-radius: 50%;
                height: 8px;
                width: 8px;
            }
        }
    }
    .card {
        border: 1px solid #E4E8ED;
        background-color: #F8F9FB;
        height: 100%;
        .card-body {
            padding: 20px;
            h5 {
                font-weight: 500;
                font-size: 16px;
                color: #008E9D;
            }
        }
    }
}

/*---- TIME MACHINE ----*/

.time-machine {
    .saveTime {
        p {
            color: #737980;
            font-size: 12px;
        }
        .saveTime_box {
            background-color: #F2F4F7;
            border-radius: 4px;
            font-size: 20px;
            margin-left: 15px;
            padding: 4px 12px;
            margin-right: 30px;
            small {
                font-size: 14px;
            }
        }
        .saveTime-btn {
            .btn {
                padding: 7px 50px;
            }
        }
    }
    table {
        thead {
            th {
                white-space: nowrap;
                vertical-align: middle !important;              
                &:first-child {
                    text-align: left;
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
        tbody {
            td {
                &:first-child {
                    text-align: left;
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}


/*---- TIME MACHINE CLOSE ----*/


/*---- MODALS ----*/

.modal {
    .modal-header {
        border-bottom: none;
        padding-bottom: 0;
    }    
    &.modal-alert {
        .modal-header {
            border-bottom: 1px solid #EBEDF3;
            padding-bottom: 1.2rem;
        }        
        .modal-body {
            padding: 1.75rem; 
        }
    }
    .modal-body {
        padding: 15px 1.75rem; 
    }
} 

.modal-card {
    margin-bottom: 16px;
    height: calc(100% - 16px);
    .card-body {
        padding: 16px 20px;
        .card-body--title {
            font-size: 12px;
            font-weight: 500;
            color: #999EA5;
            margin-bottom: 7px;
        }
        .badge--card {
            .badge--card-title {
                font-weight: 500;
            }
        }
    }
}

.info-purpose {
    font-weight: 500;
    font-size: 12px;
    margin: 10px 0 0;
    span {
        background: #D3F5F9;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        margin-left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.time_machine-modal {
    margin-top: 15px;
    h5 {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 14px;
        color: #464E57;
    }
    .card-body--title {
        font-size: 11px !important;
        color: #00ACBF !important;
        padding: 7px 12px;
        border-radius: 4px;
        display: inline-block;
        background-color: #D3F5F9;
    }
    p {
        color: #464E57;
        font-weight: 500;
    }
    .text-muted {
        color: #C1C5CB !important;
    }
    .btn-bordred {
        border: solid 1px #00acbf;
        color: #00acbf !important;
        margin-right: 10px;
    }
}

.multicolor-slider-wrap {
    .MuiSlider-root {
        .MuiSlider-rail {
            opacity: 1;
            height: 3px;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e34f4f+33,f6f062+33,f6f062+66,46bb3b+66 */
            background: #e34f4f; /* Old browsers */
            background: -moz-linear-gradient(left,  #e34f4f 33%, #f6f062 33%, #f6f062 66%, #46bb3b 66%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  #e34f4f 33%,#f6f062 33%,#f6f062 66%,#46bb3b 66%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  #e34f4f 33%,#f6f062 33%,#f6f062 66%,#46bb3b 66%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e34f4f', endColorstr='#46bb3b',GradientType=1 ); /* IE6-9 */
        }
        .MuiSlider-track {
            background: transparent !important;
        }
        .MuiSlider-mark {
            background: transparent;
            border-color: #464E57;
            margin-left: 2px;
            &[data-index="3"] {
                margin-left: 0;
            }
        }   
        .MuiSlider-valueLabel {
            left: calc(50% - 15px);
        }     
    }
    &.reverse {
        .MuiSlider-root {
            .MuiSlider-rail {
                opacity: 1;
                height: 3px;
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e34f4f+33,f6f062+33,f6f062+66,46bb3b+66 */
                background: #e34f4f; /* Old browsers */
                background: -moz-linear-gradient(left,  #46bb3b 33%, #f6f062 33%, #f6f062 66%, #e34f4f 66%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  #46bb3b 33%,#f6f062 33%,#f6f062 66%,#e34f4f 66%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  #46bb3b 33%,#f6f062 33%,#f6f062 66%,#e34f4f 66%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#46bb3b', endColorstr='#e34f4f',GradientType=1 ); /* IE6-9 */
            }
        }
    }
}

.modal-body {
    .MuiSlider-thumb {
        width: 0 !important;
        height: 0 !important;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 8px solid #0A2129;
        background: transparent !important;
        margin-top: -12px !important;
        border-radius: 0 !important;
    }  
    .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
        box-shadow: none;
    }
}


.multicolor-slider-wrap {
    .MuiSlider-root {
        .MuiSlider-rail {
            opacity: 1;
            height: 3px;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e34f4f+33,f6f062+33,f6f062+66,46bb3b+66 */
            background: #e34f4f; /* Old browsers */
            background: -moz-linear-gradient(left,  #e34f4f 33%, #f6f062 33%, #f6f062 66%, #46bb3b 66%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  #e34f4f 33%,#f6f062 33%,#f6f062 66%,#46bb3b 66%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  #e34f4f 33%,#f6f062 33%,#f6f062 66%,#46bb3b 66%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e34f4f', endColorstr='#46bb3b',GradientType=1 ); /* IE6-9 */
        }
        .MuiSlider-track {
            background: transparent !important;
        }
        .MuiSlider-mark {
            background: transparent;
            border-color: #464E57;
            margin-left: 1px;
            &[data-index="3"], &[data-index="0"] {
                margin-left: 0;
            }
        }   
    }
}
.AverageMood-slider-wrap {
    .MuiSlider-root {
        .MuiSlider-rail {
            opacity: 1;
            height: 3px;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e34f4f+0,f6f062+50,46bb3b+100 */
            background: #e34f4f; /* Old browsers */
            background: -moz-linear-gradient(left,  #e34f4f 0%, #f6f062 50%, #46bb3b 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  #e34f4f 0%,#f6f062 50%,#46bb3b 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  #e34f4f 0%,#f6f062 50%,#46bb3b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e34f4f', endColorstr='#46bb3b',GradientType=1 ); /* IE6-9 */
        }
        .MuiSlider-track {
            background: transparent !important;
        }
        .MuiSlider-mark {
            background: transparent;
            border-color: #464E57;
            margin-left: 2px;
            &[data-index="3"] {
                margin-left: 0;
            }
        }        
    }
}



.timeMin-wrap {
    margin-top: 12px;
    .timeMin-no {
        flex: 0 0 100px;
        color: #0A2129;
        .timeMin-nmbr {
            font-weight: bold;
            font-size: 32px;
            line-height: 25px;
            small {
                font-size: 14px;
                font-weight: bold;
            }
        }
        .timeMin-of {
            font-weight: 500;
            font-size: 10px;
        }
    }
    .timeMin-slider {
        .timeMin-slider-wrap {
            .MuiSlider-valueLabel {
                left: calc(-50% - 16px);
                span {
                    color: #fff;
                    span {
                        color: #fff;
                    }
                }
            }
        }
    }
}
.invite-attended {
    .invite-attended-no {
        flex: 0 0 100px;
        color: #0A2129;
        .averagemood-nmbr {
            font-weight: bold;
            font-size: 32px;
            small {
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
}

.modal-sub--title {
    color: #737980;
    font-size: 12px;
    margin-top: 5px;
}

.howCompare-card {
    .MuiSlider-valueLabel {
        left: calc(-50% - 16px) !important;
    }
    p {
        font-size: 12px;
        font-weight: 400;
    }
    .auth-selectgroup {
        .auth-selectgroup-item {
            .selectgroup-button {
                padding: 0 8px;
                font-size: 12px;
            }
        }
    }
    .howCompare-slider {
        .MuiSlider-mark {
            background: transparent;
        }
        .MuiSlider-thumb {
            border-top: 8px solid $primary !important;
        }
        .MuiSlider-rail {
            opacity: 1;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d3f5f9+0,00acbf+50,02444c+100 */
            background: #d3f5f9; /* Old browsers */
            background: -moz-linear-gradient(left,  #d3f5f9 0%, #00acbf 50%, #02444c 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  #d3f5f9 0%,#00acbf 50%,#02444c 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  #d3f5f9 0%,#00acbf 50%,#02444c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d3f5f9', endColorstr='#02444c',GradientType=1 ); /* IE6-9 */

        }
        .MuiSlider-track {
            background: transparent !important;
        }
        &.howCompare-multicolor {
            margin-top: 14px;
            .MuiSlider-rail {                
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e34f4f+0,f6f062+50,46bb3b+100 */
                background: #e34f4f; /* Old browsers */
                background: -moz-linear-gradient(left,  #e34f4f 0%, #f6f062 50%, #46bb3b 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  #e34f4f 0%,#f6f062 50%,#46bb3b 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  #e34f4f 0%,#f6f062 50%,#46bb3b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e34f4f', endColorstr='#46bb3b',GradientType=1 ); /* IE6-9 */
                }
        }
        
        .MuiSlider-markLabel {
            font-size: 10px;
            top: 30px;
            > span {
                position: relative;
                top:-15px;
            }
        }
    }
}

.multicolor-slider {
    .multicolor-slider-numr {
        font-weight: bold;
        font-size: 32px;
        color: #0A2129;
        span {
            font-size: 12px;
            color: #999EA5;
            font-weight: 400;
        }
    }
}

.showhide-accordion {
    margin-top: 8px;
    .showhide--btn {
        h4 {
            font-size: 18px;
            font-weight: bold;
            color: #0A2129;
        }
    }
}
.btn-show--hide {
    border-color:#E4E8ED !important;
    background-color: #fff;
    border-radius: 4px;
    padding: 4px 11px !important;
    font-size: 11px !important;
    font-weight: 500 !important;
    color: #0A2129 !important;
    &:hover {
        background-color: #E4E8ED;
    }
}



.improve-agenda {
    margin-top: 30px;
    .agenda-row {
        margin-bottom: 15px;
        .agenda-no {
            .agenda-no-col {
                font-weight: bold;
                font-size: 14px;
                span {
                    background: #F2F4F7;
                    border-radius: 50%;
                    height: 21px;
                    width: 21px;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 22px;
                    margin-right: 8px;
                    flex: 0 0 21px;
                }
                p {
                    display: inline-block;
                    flex-grow: 0;
                }
                sup {
                    margin-left: 5px;
                    top: 0;
                    white-space: nowrap;
                }
            }
            .btn-show--hide {
                white-space: nowrap;
            }
        }
        .agenda-body {
            
            p {
                color: #464E57;
                font-size: 12px;
                &:first-child {
                    margin-top: 12px;
                }
            }
        }
    }
    .stars-decl {
        margin-top: 27px;
        div {
            display: flex;
            align-items: center;
            font-size: 11px;
            color: #999EA5;
            margin-bottom: 8px;
            span {
                display: block;
                width: 39px;
            }
        }
    }
}

.avatar-group {
    display: inline-flex;
    .avatar {
        position: relative;
        display: inline-block;
        &:hover {
            z-index: 1;
        }
        &.avatar-sm {
            width: 2.3rem;
            height: 2.3rem;
            .avatar-img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                &.border {
                    border-width: 2px !important;
                }
            }
            & + .avatar-sm {
                margin-left: -.625rem;
            }
        }
    }
}


.your-badges--card {
    border: 1px solid #E4E8ED;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px 20px;
    margin: 10px 0 5px;
    min-height: calc(100% - 16px);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.disabled {
        pointer-events: none;
        opacity: .5;
    }
    img {
        flex-grow: 1;
        margin-bottom: 15px;
    }
    p {
        margin: 0;
        font-weight: bold;
    }
}


/*---- COOKEI MODALS ALERT ----*/

.alert-modal {
    .modal-alert-icon {
        text-align: center;
        margin-bottom: 20px;
    }
}
.cookie-modal {
    .modal-header {
        background-color: #11adbf!important;
        padding-bottom: 8px;
        padding-top: 11px;
        .modal-title {
            color: #fff!important;
        }
        .close {
            font-size: 16px;
            color: #fff !important;
            opacity: 1;
            position: relative;
            top: -1px;
            right: -8px;
        }
    }
    .alert-modal {
        .modal-alert-icon {
            text-align: center;
            margin-bottom: 20px;
        }
        .w3-section {
            text-align: center;
            .centerArea {
                max-width: 650px;
                width: auto;
                margin: 0 auto;
                padding: 0 30px;
                border: 1px solid #14adc02e;
                
                @media (max-width: 992px) {                    
                    padding: 0 15px;
                }
                .cookie-loader {
                    text-align: center;
                    img {
                        width: 70px;
                    }
                }
                .logo {
                    text-align: center;
                    margin-bottom: 23px;
                    margin-top: 21px;
                    img {
                        max-width: 100%;
                        margin: 0 auto;
                    }
                }
                .textblock {
                    display: block;
                    text-align: left;
                    .cookies-tabs {
                        margin-top: 20px;
                        .nav-tabs {
                            padding: 0 20px;
                            justify-content: center;
                            @media (max-width: 560px) {                    
                                padding: 0 7px;
                            }
                            .nav-link {
                                font-size: 18px;
                                padding: 9px 22px;
                                opacity: .6;
                                @media (max-width: 560px) {                    
                                    padding: 9px 10px;
                                }
                                &.active, &.show {
                                    color: $primary;
                                    opacity: 1;
                                }
                                img {
                                    width: 25px;
                                    height: auto;
                                    margin-right: 6px;
                                }
                            }
                        }
                        .tab-pane {
                            padding: 20px 0;
                            .row {
                                margin-top: 15px;
                                margin-bottom: 40px;
                                .cookies-content {
                                    span {
                                        flex: 0 0 35px;
                                        background-color: #ced7da;
                                        height: 35px;
                                        border-radius: 50%;
                                        text-align: center;
                                        color: #444;
                                        line-height: 1.9;
                                        font-weight: bold;
                                        font-size: 18px;
                                        margin-right: 15px;
                                    }
                                    p {
                                        margin: 0;
                                    }
                                }
                                .cookies-img {
                                    img {
                                        max-width: 100%;
                                    }
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }  
    }
    .close-btn-footer {
        margin: 17px -25px -15px;
        @media (max-width: 992px) {  
            margin: 17px -21px -15px;
        }
        .btn {
            width: 100%;
            padding: 8px !important;
            background-color: #00ACBF;
            color: #fff;
            border-radius: 0;
            &:hover {
                background-color: #ccc;
                color: #333;
            }
        }
    }
}


.leaderBoard-modal {
    color: var(--main-black);
    .modal-card {
        .efficiency-pro {
            p {
                font-weight: 500;
                color: #737980;
                img {
                    width: 12px;
                    margin-right: 9px;
                }
            }
        }
        h5 {
            font-weight: 500;
            color: #737980;
            font-size: 14px;
            line-height: 21.28px;
            strong {
                color: #0A2129;
            }
        }
    }
    .leaderboard-cards {
        p {
            color: #999EA5;
            font-weight: 500;
            font-size: 12px;
        }
        span {
            font-weight: bold;
            font-size: 20px;
        }
    }
    .btn-bordered {
        font-size: 11px;
        line-height: 11px;
        padding: 6px 12px 5px;
        border: solid 1px #E4E8ED;
    }
    .leaderBoard-table-container {
        padding-top: 15px;
        .leaderBoard-table-header {
            h4 {
                font-size: 16px;
            }
        }
        .leaderBoard-table-body {
            @media (max-width: 992px) { 
                overflow-x: auto;
            }
            .rdt_TableRow, .rdt_TableHeadRow {
                div {
                    text-align: center;
                    &:first-child {
                        padding-left: 0 !important;
                        text-align: left;
                    }
                    &:last-child {
                        padding-right: 0 !important;
                        text-align: right;
                    }
                }
            }
            .leadeboard-user-info {
                display: flex;
                align-items: center;
                .leaderboard-rank {
                    flex: 0 0 19px;
                    white-space: nowrap;
                    text-align: left;
                }
                .leaderboard-initials {
                    width: 28px;
                    height: 28px;
                    flex: 0 0 28px;
                    margin-right: 12px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: left;
                    background: #d0f3f7;
                    color: #099baa;
                    border: 1px solid #abe8f0;
                }
                .leaderboard-name {
                    color: #0A2129;
                    text-align: left;
                }
            }


            table {
                thead {
                    tr {
                        th {
                            color: #737980;
                            font-size: 12px;
                            font-weight: 400;
                            text-align: center;
                            &:first-child {
                                text-align: left;
                                padding-left: 0;
                            }
                            &:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            text-align: center;
                            &:first-child {
                                text-align: left;
                                padding-left: 0;
                                min-width: 172px;
                                display: flex;
                                align-items: center;
                                flex-wrap: nowrap;
                            }
                            &:last-child {
                                padding-right: 0;
                            }
                            &.leaderBoard_user {
                                img {
                                    width: 28px;
                                    height: auto;
                                    margin: 0 10px 0 13px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.yourAchievements-modal {
    .yourAchievements-header {
        font-weight: 700;
        font-size: 16px;
        color: #02444C;
        text-align: center;
        margin-bottom: 17px;
    }
    .yourAchievements-card {
        .card-body {
            text-align: center;
            img {
                margin-bottom: 15px;
            }
            h5 {
                font-weight: 700;
                font-size: 14px;
                color: #0A2129;
            }
            p {
                font-weight: 400;
                font-size: 14px;
                color: #737980;
                margin-bottom: 0;
                strong {
                    color: #0A2129;
                }
            }
        }
    }
    .yourbadges-header {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 9px;
        margin-top: 24px;
    }
}

.MuiSlider-markLabel {
    top: 17px;
    z-index: 2;
}
.companyRatings-chart {
    .companyRatings-tilte {
        p {
            font-size: 12px;
        }
    }
    .companyRatings-body {
        padding-top: 20px;
        padding-left: 3px;
        @media (max-width: 1200px) {
            padding-left: 7px;
        }
        .MuiSlider-track {
            background: transparent !important;
        }
        .MuiSlider-mark {
            background: transparent;
            border-color: #464E57;
            margin-left: 2px;
        } 
        .MuiSlider-rail {  
            opacity: 1;
            height: 3px;              
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e34f4f+0,f6f062+50,46bb3b+100 */
            background: #e34f4f; /* Old browsers */
            background: -moz-linear-gradient(left,  #e34f4f 0%, #f6f062 50%, #46bb3b 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  #e34f4f 0%,#f6f062 50%,#46bb3b 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  #e34f4f 0%,#f6f062 50%,#46bb3b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e34f4f', endColorstr='#46bb3b',GradientType=1 ); /* IE6-9 */
        }
        .MuiSlider-thumb {
            background-color: transparent;
            margin-top: -11px;
            border-radius: 0;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 8px solid $primary;
            > span {
                top: -32px;
                > span {
                    background-color: transparent;
                    left: 8px;
                    > span {
                        color: #737980;
                        font-size: 12px;
                        display: inline-block;
                        width: 22px;
                        text-align: right;
                        padding-right: 3px;
                        position: relative;
                        margin-right: 8px;
                        margin-top: -8px;
                    }
                }
            }
            .MuiSlider-valueLabel {
                left: calc(-50% - 35px);
            }
            &:hover {
                box-shadow: none !important;
            }
        }
        .MuiSlider-markLabel {
            top: 30px;
            > span {
                position: relative;
                top: -15px;
                padding-right: 8px;
                @media (max-width: 1200px) {
                    top:-10px;
                }
                > i {
                    margin-left: 9px;
                }
                > span {
                    display: inline-block;
                    position: absolute;
                    left: -18px;
                    width: 24px;
                    text-align: right;
                    margin: 0;
                }
            }
        }
    }
}

.badge-level-container {
    flex-wrap: nowrap;
    margin-top: -13px;
    @media (max-width: 1200px) {
        overflow-x: auto;
    }
    .badge-level-wrap {
        flex-grow: 1;
        margin-left: -2px;
        margin-right: -2px;
        @media (max-width: 1200px) {
            overflow: hidden;
            min-width: 800px;
        }
        .badge-level {
            margin: 29px 2px 35px;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            position: relative;
            &:first-child {
                .level-indicater {
                    span {
                        left: -28px !important;
                        bottom: -27px !important;
                        font-size: 10px !important;
                        text-align: center;
                        line-height: 8px;
                    }
                }
            }
            p {
                color: #737980;
                font-size: 10px;
            }
            .badge-icn {
                position: absolute;
                right: 0;
                bottom: 4px;
            }
            .badge-level-slider {
                position: relative;
                height: 2px;
                background: #C1C5CB;
                .level-progress {
                    position: absolute;
                    height: 2px;
                    left: 0;
                    width: 0;
                    top: 0;
                    background: #00ACBF;
                }
            }
            .level-indicater {
                display: none;
            }
            h5 {
                color: #999EA5;
                font-size: 12px;
                position: absolute;
                right: 2px;
                top: calc(100% + 2px);
            }
            img.disabled{
                opacity:0.6;
            }
            &.completed {
                .badge-level-slider {
                    .level-progress {
                        width: 100%;
                    }
                }
            }
            &.active {
                .badge-level-slider {
                    .level-progress {
                        // STATIC WIDTH //
                        width: 20%;
                    }
                }
                .level-indicater {
                    color: #464E57;
                    font-size: 11px;
                    position: absolute;
                    bottom: -8px;
                    // left: 30%;
                    margin-left: 1rem;
                    display: inline-block;
                    span {
                        position: absolute;
                        left: -30px;
                        bottom: -30px;
                        width: 60px;
                        white-space: nowrap;
                        text-align: center;
                    }
                    i {
                        position: absolute;
                        left: -5px;
                        bottom: -18px;
                        color: #0A2129;
                    }
                }
            }
        }
    }
}
.splash-spinner .path {
    stroke: #00ACBF;
}
.table-filter {
    display: flex;
    align-items: center;
    .table-filter--icn {
        display: inline-flex;
        flex-direction: column;
        margin-left: 8px;
        .fas {
            line-height: 6px;
            font-size: 12px;
            color: #999EA5;
            &.active {
                color: #0A2129;
            }
        }
    }
}

// .apexcharts-yaxis-title {
//     text {
//         transform: rotate(270deg);
//         transform-origin: left;
//     }
// }

.time_machine-modal-small {
    .modal-sm-title {
        font-size: 20px;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 20px;
    }
    img {
        margin-bottom: 25px;
        max-height: 56px;
    }
    .modal_btns {
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .btn-bordred {
        border: solid 1px $primary;
        color: $primary !important;
        margin-right: 10px;
        &:hover {
            background-color: #D3F5F9;
        }
    }
    .btn-primary {
        margin-left: 10px;
    }
}

.Weekly-Score-chart {
    position: relative;
    .detail-percentage {
        position: absolute;
        color: #46BB3B;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        justify-content: center;
        width: 100%;
        bottom: 29%;
        img {
            margin-top: -2px;
            margin-left: 3px;
        }
    }
}

@media (min-width: 576px) and (max-width: 992px){
    .score-modal, .leaderBoardModal {
        .modal-dialog {
            max-width: calc(100% - 20px) !important;
        }
    }
}

.compare-icon-blk {
    display: flex;
    flex-direction: column;
    line-height: 6px;    
    margin-top: -3px;
    position: relative;
    i {
        color: #111;
    }
    .compare-icon-blk-value {
        opacity: 0;
        pointer-events: none;
        color: #273443;
        background-color: #fff;
        padding: 6px 8px;
        position: absolute;
        border-radius: 3px;
        font-size: 12px;
        bottom: 24px;
        left: -10px;
        line-height: 16px;
        white-space: normal;
        width: 177px;
        display: block;
        box-shadow: 0px 4px 7px rgba(46, 53, 62, 0.12);
        &:after {
            position: absolute;
            content: "";
            left: 10px;
            bottom: -7px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 7px solid #fff;
            z-index: 1;
        }
    }
    &.active {
        .compare-icon-blk-value {
            opacity: 1;
            pointer-events: visible;
        }
    }
    &.left {
        .compare-icon-blk-value {
            left: auto !important;
            right: -10px;
            &:after {
                left: auto !important;
                right: 10px;
            }
        }
    }
}

.YourAchievementsCard {
    .card-body {
        .card-overlay {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: bold;
            background-color: rgb(211 245 249 / 84%);
            width: calc(100% - 30px);
            height: 100%;
            left: 15px;
            top: 0;
            z-index: 1;
            padding: 20px;
            text-align: center;
            @media (max-width: 767px) {
                width: calc(100% - 20px);
                left: 10px;
            }
        }
    }
}

.coaching-page {
    height: 100%;
}
.coaching-page .card.card-custom.card-coaching > .card-body {
    padding: 0 12px 16px 24px;
}
.coaching-page-col-2 {
    max-height: calc(100vh - 105px);
    overflow-y: auto;
}
.coaching_card-scroll {
    height: 100%;
    overflow-y: auto;
    padding-right: 12px;
    max-height: calc(100vh - 172px);
    h5 {
        font-weight: 500;
        font-size: 14px;
        margin-top: 20px;
    }
    .coaching_card {
        margin-top: 15px;
        border: 1px solid #E4E8ED;
        border-radius: 4px;
        .coaching_card-header {
            background: #F8F9FB;
            padding-left: 15px;
            border-radius: 4px 4px 0 0;
            border-bottom: 1px solid #E4E8ED;
            .time {
                font-size: 16px;
            }
            .date-btn {
                color: #999EA5;
                font-size: 12px;
                .btn {
                    font-size: 12px;
                    color: #FFFFFF;
                    margin-left: 15px;
                    padding: 7px 26px;
                    min-width: 132px;
                    @media (max-width: 1200px) {
                        padding: 7px 9px;
                    }
                }
                .btn-add-agenda {
                    background: #FCAC34;
                }
                .btn-add-mins {
                    background: #00ACBF;
                }
                .btn-decline {
                    background: #ffc107;
                    font-weight: 500;
                  
                }
            }
        }
        .coaching_card-body {
            padding: 15px;
            .coaching-detail {
                h4 {
                    margin: 0;
                    font-size: 16px;
                    color: #02444C;
                    font-weight: bold;
                }
                .agenda-detail {
                    min-width: 119px;
                    img {
                        margin-right: 4px;
                    }
                    span {
                        font-size: 16px;
                        font-weight: bold;
                        display: inline-flex;
                        align-items: baseline;
                        small {
                            font-size: 12px;
                            font-weight: 500;
                            margin-left: 3px;
                        }
                        &:first-child {
                            margin-right: 13px;
                        }
                    }
                }
            }
            .avatar-group {
                margin-top: 12px;
            }
        }
    }
}

.custom-chart-title {
    display: flex;
    .chart-title {
        flex: 0 0 20px;
        max-width: 20px;
        .chart-title-name {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            padding-bottom: 130px;
            padding-top: 61px;
            > div {
                transform: rotate(270deg);
                span {
                    display: inline-block;
                    color: #999EA5;
                    text-transform: uppercase;
                    font-size: 10px;
                }
                &:first-child {
                    span {
                        position: relative;
                        left: -26px;
                    }
                }
                &:nth-child(2) {
                    span {
                        position: relative;
                        left: -12px;
                    }
                }
            }
            @media (max-width: 1400px) {
                padding-bottom: 124px;
            }
            @media (max-width: 1060px) {
                padding-bottom: 160px;
            }            
            @media (max-width: 992px) {
                padding-bottom: 124px;
            }
            @media (max-width: 767px) {
                padding-bottom: 87px;
            }
        }
    }
    .chart-div {
        flex-grow: 1;
        div {
            div {
                svg {
                    overflow: visible !important;
                }
            }
        }
    }
}

.menu-item  .disabled{
    opacity: 0.3;
}

.menu-item .disabled:hover{
    opacity: 1 !important;
}

.modal-body .time-min-slider-thumb{
    width: 2px !important;
    height: 15px  !important;
    display: flex  !important;
    outline: 0  !important;
    position: absolute  !important;
    box-sizing: border-box  !important;
    margin-top: -6px  !important;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms  !important;
    align-items: center  !important;
    margin-left: 0px  !important;
    border-radius: 0%  !important;
    justify-content: center  !important;
    background-color: currentColor  !important;
    border: none !important;
    background: #12acbf !important;
}

 .time-min-slider-mark + .MuiSlider-markLabelActive{
    color: #737980 !important;
    font-size: 11px !important;
}

.no-refresh-bar{
    background-color: #ffeebe;
    position: absolute;
    left: 15%;
    top: 1%;
    color: #000;
    font-size: 12px;
    padding: 15px 5px 0px 5px;
    border:1px solid #f4bd3b;
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
    top: 20px;
    padding: 10px 20px;
    left: 10%;

}
.no-refresh-bar p 
{
    margin-bottom: 0;
}


.animated-loader{
    z-index: 10000;
    background-color: #000;
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    text-align: center;
    opacity: .7;
    align-items: center;
    justify-content:baseline;
}

.animated-loader .loader-logo-rounded{
    // height: 230px;
    // width: 250px;
    // border: 2px solid #22bac9;
    // border-radius: 50%;
    // padding: 40px 0px;
    // box-shadow: 0px 0px 20px #00626C;
}

.animated-loader .loader-content{
    position: absolute;
    top: 40%;
    text-align: center;
    width: 100%;
}


.animated-loader .loader-content p {
    color: #fff;
           // display: inline-flex;
            font-size: 16px;
            line-height: 1.5;
            margin: 20px 0 0;
            max-width: 100%;
            padding: 0 47px;
            text-align: center;
            //width: 550px;
            font-weight: bold;
}


.mcr-link-step6{
    margin: 10px 0;
}

.mcr-link-step6 a{
    display: block;
    color: #12acbf;
    background-color: #ffffff;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    border: 2px solid #12acbf;
    /* box-shadow: -3px 8px 8px #12acbf; */
    border-radius: 7px;
}

.mcr-link-step6 a:hover{
    color: #fff;
    background-color: #12acbf;
}

.recc-block{
    border: 1px solid #ddd;
    align-items: center;
    border-radius: 5px;
}

.recc-timestamp-block{
    padding: 10px;
    background-color: #f8f9fb;
    border-right: 1px solid #ddd;
}

.event-day{
    font-weight: bold;
    font-size: 18px;
}

.event-date{
    color: #a9aeb5;
    font-weight: bold;
}

.recc-title-block, .recc-action-btn-block{
    align-items: center;
}

.recc-title{
    font-weight: bold;
    font-size: 18px;
 
}


.spin-img {
  
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.sadness-img{
    max-width: 500px !important;
}

.btn3{
 background: #f2f4f7 !important;
 color: #3F4254 !important;
 font-weight: normal !important;
 padding-right: 30px !important;
 padding-left: 30px !important;
}

.btn4{
    background-color: #fff !important;
    border: 2px solid #00acbf !important;
    color: #00acbf !important;

}

.btn4:hover{
    color: #fff !important;
    background: #00acbf !important;
}

.timezone-container{
    position: relative;
}

.loading-timezones{
    position: absolute;
    right: 14%;
    top: 50%;
}

#otp {
    margin: -.5rem;
}


.msgClass{
    color: #00acbf;
}

.otpError{
    color: red;
}

.btn-microsoft-login{
    // width: 236px !important;
    // padding-right: 102px !important;
    // border-radius: 13px !important;
    width: 220px;
    padding-right: 102px;
    border-radius: 13px;

}
.summary_meeting_line{
    font-size: 11px !important;
}
.summary_meeting_line1{
    margin: 0px !important;
}

.auth-container .left_col .auth_section .auth_content .auth_buttons .google-button:hover,
.auth-container .left_col .auth_section .auth_content .auth_buttons .btn-container .btn-auth:hover {
    background: #D3F5F9 !important;
    border-color: #00acbf !important;
}

// .auth-container .left_col .auth_section .auth_content .auth_buttons .google-button:hover > div,
// .auth-container .left_col .auth_section .auth_content .auth_buttons .btn-container .btn-auth:hover > img {
//     border-color: #bfbfbf !important;
// }

.google-button,.btn-auth{
    transition: all 0.3s ease !important;
}
.aside-menu .menu-nav > .menu-item {
    position: relative;
    margin: 0 0 2px !important;
}
.howCompare-slider .MuiSlider-root, .companyRatings-body .MuiSlider-root{
    cursor: default !important;
}
.SDK-login-Button{
    background: rgb(242, 244, 247);
    border: 1px solid rgb(242, 244, 247);
    border-radius: 4px;
    color: rgb(52, 59, 67);
    font-size: 14px;
    padding: 13px;
    min-width: 222px;
    text-align: left;
    min-height: 51px;
    margin-bottom: 16px;
}
.SDK-login-Button{
    transition: all 0.3s ease !important;
}
.SDK-login-Button:hover{
    transition: all 0.3s ease !important;
    background: #D3F5F9 !important;
    border-color: #00acbf !important;
}
.SDK-google-icon
{
    border-right: solid 1px #E4E8ED;
    padding-right: 13px;
    margin-right: 10px;
    display: inline-block;
}

.aside-minimize .nav_footer .dropdown-menu {
    left: 0 !important;
}

a::selection {
    color: #026b76 !important;
    background: rgb(175 226 233 / 66%) !important;
  }
img::selection, br::selection, li::selection, input::selection, label::selection, small::selection, div::selection, u::selection, select::selection, span::selection, p::selection, table tr td::selection, th::selection, strong::selection, b::selection, h1::selection ,h2::selection, h3::selection, h4::selection, h5::selection, h6::selection {
    color: rgb(0 0 0) !important;
    background: #8FE0E9 !important;
    // background: #00acbf82 !important;
  }
