.phone-no-check-btn{
position: relative;
top: 25px;
}
.send_otp .form-control
{
width: 100%;
max-width: 300px;
}
.edit-profile-text-center .form-control-solid{
  padding: 0.65rem 0rem !important;
  text-align: center !important;
}
@media screen and (max-width: 1350px) and (min-width: 1218px) {
  .edit-profile-verify-button{
    margin-left: -20px !important;
    margin-right: 20px !important;
  }
    .send_otp{
        margin-right: 0;
        padding-right: 10px;
    }
    .phone-no-check-btn{
        position: relative;
        top: 25px;
    }
    /* .send_otp_button{
      top: -20px !important;
        margin-top: 40px;
    } */
  }
  .error_massage{
      margin-top: 15px;
  }
  .msgClass{
    width: 422px !important;
    margin: 0px;
    padding: 0px;
      margin-top: -19px !important;
      margin-bottom: 20px !important;
  }
  /* .react-select{
      background-color: aqua;
      height: 150px;
  }
  .react-select1{
      background-color: aqua;
      height: 150px;
  } */

  .css-8mmkcg{
    margin-top: 8px !important;
  }

  .react-select1__value-container{
    background-color: #F8F9FB;
    height: 60px;
}

.css-tj5bde-Svg{
    height: 5rem !important;
    padding-bottom: 2.5rem !important;
}
.css-1okebmr-indicatorSeparator {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
  /* .react-select__control.react-select__control{
      color: antiquewhite;
      width: 50px;
      height: 150px;

  } */


/* @media screen and (max-width: 1350px) and (min-width: 658px) {
    .send_otp{
        margin-right: 10px;
        padding-right: 10px;
    }
    .phone-no-check-btn{
        position: relative;
        top: -57px;
        right: -50px;
    }
  } */

  /* .msgClass{
      position: relative;
    top: -25px;
  } */
  /* ------------------------------------------ */
  .selectdiv:after {
    content: '>';
    font: 17px "Consolas", monospace;
    color: #b6b7b9;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    background-color: #F8F9FB;
    /*Adjust for position however you want*/
    top: 40.8px;
    right:-4px;
    padding: 5px 21px 6px 22px;
    border-bottom: 1px solid #b6b7b9;
    /*left line */
    position: absolute;
    pointer-events: none;
 }

  .selectdiv select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  /* .css-1pahdxg-control{

      box-shadow: 0 0 0 1px #00ACBF !important;
    } */

    .upload-btn #browse-file{
        visibility: hidden;
    }
    .react-select1__value-container{
      height:56.85px !important;
    }
    /* phone input height */
    /* .country-code-input{
      height:56.85px !important;
    } */
   .add-pic
   {
    position: relative;
   }
    button.remove_photo_cross_icon.upload-btn.btn {
      position: absolute !important;
      top: 138px;
      padding: 0;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      color: #fff;
      display: flex !important;
      align-items: center;
      justify-content: center;
      right: 0;
      left: 0;
      opacity: 0.8;
      margin: auto;
      background: red;
      
  }
  .remove_photo_cross_icon:hover 
  {
    opacity: 1 !important;
  }

  .send_otp_button{
    top: 34px !important;
  }
  span.required-astrik-sing {
    color: rgb(227, 79, 79);
    position: relative;
    top: -2px;
    left: -1px;
}
.send_OTP_Custom{
  z-index: 9;
    position: relative;
}