#header_conditional_class{
    position: relative;
    left:4rem;
}
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper{
    font-size: 14px !important;
    color: #343B43 !important;
}
.handlemodal .modal-dialog
{
    max-width: 700px;
    border-radius: 15px;
    background-color: #763CFF;
    overflow: hidden;
    min-height: inherit !important;
}