.org_rated_arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 1rem;
    transition: 0.6s;
}

.org_rated_arrow_up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.org_rated_arrow_down {
    margin-bottom: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.rated-meetings_custom_popover {
    padding: 0.75rem 0;
    vertical-align: top;
    border-top: 1px solid #EBEDF3;
}

#chart .apexcharts-menu .exportCSV, .custom-download-p-t .apexcharts-menu .exportCSV{
    display: none !important;

}
.rm-me-for-original-color{
    background-color: #b2d0d3 !important;
}