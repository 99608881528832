.msg-text {
    font-size: 20px;
    text-align: center;
    color: #e34f4f;
    font-weight: 500;
}
.msg-success {
    font-size: 20px;
    text-align: center;
    color: #00acbf;
    font-weight: 500;
}
.cross-img {
    border-radius: 100%;
}
.error-page button.close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    border-radius: 1000%;
    opacity: 1;
    z-index: 99;
}
.error-page .close:hover 
{
    background-color: #eee;
}