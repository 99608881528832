
.notification_navigation, .relationship_navigation,.editpersonalinfo_navigation,.feedback_navigation,.privacy_navigation,.personalInfo_navigation{
    position: sticky !important;
    top: 80px;
    z-index: 999 !important;
    background: #f8f9fb !important;
}
.notification_profileTab , .relationship_profileTab , .editpersonalinfo_profileTab, .feedback_profileTab,.privacy_profileTab,.personalInfo_profileTab{
    margin-top:0px;
    }



.privacy-permission
{
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
}
.privacy-permission .Meeting-label
{
    width: 100%;
    max-width: 90%;
}

.privacy-wrap .card .card-body .privacy-permission h5
{
    width: 100%;
}
.privacy-permission .Meeting-label .help-block 
{
    max-width: 100%;
}




    /* .profile-container .nav-container {
        position: sticky !important;
        top: 0 !important;
        z-index: 999 !important;
        background:#f8f9fb !important;
    }
    .profile-container .nav-container .tab-content {
        position: relative !important;
        z-index: 99 !important;
    } */

@media screen and (max-width:1200px)
{
    .profile-container .nav-container .nav-scrollable .nav .nav-item .nav-link {
        padding: 0.75rem 12px;
        font-size: 14px;
    }
    .profile-container .nav-container .edit-button .btn.btn-primary {
        padding: 8px 10px;
    }
    .profile-container .nav-container .edit-button .btn.btn-edit {
        padding: 8px 20px;
    }
}
@media screen and (max-width:991px)
{
    .ms--wrapper .wrapper .header-fixed
    {
        height: 45px;
    }
    .notification_navigation, .relationship_navigation, .editpersonalinfo_navigation, .feedback_navigation,.privacy_navigation,.personalInfo_navigation
    {
        top: 55px;
    }
    .profile-container .nav-container .edit-button
    {
        bottom: inherit !important;
    }
}
@media screen and (max-width:767px)
{
    .profile-container .nav-container .edit-button {
        bottom: 60px !important;
    }
    .notification_navigation, .relationship_navigation, .editpersonalinfo_navigation, .feedback_navigation,.privacy_navigation,.personalInfo_navigation
    {
        z-index: 9 !important;
    }
}

.delete_account_text_p{
    margin-left: 2rem;
    display: inline-flex;
    justify-content: center;
    min-height: 2.4rem;
    margin-right: 2rem;
    min-width: 64px;
    align-items: center;
    vertical-align: middle;
}
.delete_account_text_p:hover{
background-color: rgba(178, 49, 40, 0.04);
}
.delete_account_text{
    color: rgb(178, 49, 40);
    cursor: pointer;
    user-select: none;
    font-family: "Work Sans", sans-serif;
    font-weight: 500;
    line-height: 1.15;
    text-transform: none;
    min-width: 64px;
}
.yes_delete{
    color: rgb(178, 49, 40) !important;
    cursor: pointer;
    user-select: none;
    font-family: "Work Sans", sans-serif;
    font-weight: 500;
    text-transform: none;
    min-width: 64px;
}
.delete-confirmation-label{
    text-align: left !important;
    font-size: 16px;
}
.delete-confirmation{
    text-align: left !important;

}