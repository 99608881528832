.setting_title{
    color: black;
    font-weight: 400;
    padding: 10px 0;
}

.setting_item{
    padding: 7px 0;
}

 .switch input:checked ~ span:before, .switch input:empty ~ span:before{
    background-color: #E1E1E1 !important;
}