@media screen and (max-width: 1280px) {
  .Notifications-wrap .card .card-body .auth-selectgroup .auth-selectgroup-item .zoom-selectgroup-button {
    padding: 8px 4px !important;
  }

}
@media screen and (max-width: 1350px) and (min-width: 1218px) {
  .responsive{
      /* padding-left: -50px !important; */
      margin-left: -35px !important;
    }
    .uninstall_zoom-app{
      margin-left: -1rem !important;
    }
    /* -------------------------new-------------------------- */
    .meeting-slider .meeting-slider-line .MuiSlider-root {
    width: 350px !important;
    display: inline-block !important;
  }
  .meeting-slider-line{
    width: 350px !important;
  }
/* -------------------------new-------------------------- */

}
@media screen and (max-width: 1490px) and (min-width: 1350px) {
  .responsive{
      /* padding-left: -50px !important; */
      margin-left: -35px !important;

  }
}
@media screen and (max-width: 1217px) and (min-width: 1060px) {
  .responsive{
      /* padding-left: -50px !important; */
      margin-left: -45px !important;

  }
}
@media  (min-width: 1366) {
  .feedback_navigation  .selectgroup-button {
    padding: 8px 4px !important;
}
}


.meeting-controle{
  border: 1px solid #E4E8ED;
  background-color: #F8F9FB;
  /* height: 100%; */
  position: relative;
  width: 100%;
  margin-top: 20px;
  /* margin-bottom: 20px; */
  border-radius: 4px;
}
.meeting-controle .card-body{
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  /* background-color: #ffffff; */
  background-clip: border-box;
  /* border: 1px solid #EBEDF3; */
  border-radius: 4px;
}
.meeting-controle .card-body .meeting-feedback h5,
.meeting-controle .card-body .which-meeting h5,
.meeting-controle .card-body .private-meetings h5,
.meeting-controle .card-body .receive-reminder h5,
.meeting-controle .card-body .meeting-slider h5,
.meeting-controle .card-body .meeting-size h5
{
  font-weight: 500;
  font-size: 16px;
  color: #008e9d;
  /* margin-bottom: 17px; */
  /* display: inline-block;
  float: left; */
  margin-bottom: 3px;
  flex: 0 0 100%;
  /* margin-right: 7rem; */

}
.meeting-size .meeting-size-dropdown select:focus {
  border-color: #008e9d;
  outline: none;
}
.which-meeting-labels .selectgroup-button , 
.private-meetings-labels .selectgroup-button,
.receive-reminder-labels .selectgroup-button
{
  display: block;
  border: 1px solid #e4eaf0;
  text-align: center;
  padding: 8px 16px;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  color: #8492a5;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  line-height: 1.5rem;
  min-width: 40px;
  text-transform: capitalize;
  letter-spacing: 0;
}
.which-meeting-labels input:checked+.selectgroup-button , 
.private-meetings-labels input:checked+.selectgroup-button,
.receive-reminder-labels input:checked+.selectgroup-button
{
  border-color: #00acbf;
  z-index: 1;
  color: #00acbf;
  background: #d3f5f9;
}
.which-meeting-labels input , 
.private-meetings-labels input,
.receive-reminder-labels input
{
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}
.which-meeting-labels label , 
.private-meetings-labels label,
.receive-reminder-labels label
{
  padding-right: 10px;
}

.meeting-feedback, .which-meeting, .private-meetings, .receive-reminder, .meeting-slider, .meeting-size{
  margin-bottom: 20px;
  /* border-bottom: 1px solid #e4eaf0; */
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.which-meeting, .private-meetings, .receive-reminder, .meeting-slider{
  border-bottom: 1px solid #e4eaf0;
}
.meeting-size{
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.Meeting-label {
  display: flex;
  flex-wrap: wrap;
  max-width: 370px;
  margin-right: 20px;
  margin-bottom:1rem;
}
.meeting-controle .card-body .meeting-size-dropdown{
width: 190px;
display: inline-block;
height: 50px;
/* background-color: #008e9d; */
}

.meeting-size .meeting-size-dropdown select {
background-color: #F8F9FB;
border:1px solid #b7b7b7;
border-radius: 3px;
color: #616161;
padding: 8px 10px;
min-width: 100px;
}

.meeting-size{
  margin-bottom: 20px;
  /* border-bottom: 1px solid #e4eaf0; */
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
/* ----------------------meeting-slider css start------------------------------ */
.meeting-slider-line{
display: inline-block;
padding-left: 10px;
}
.meeting-slider-line .pt-6 ,   .meeting-slider-line .mt-6{
padding-top: 0% !important;
margin-top: 0% !important;
}

.auth-slider .MuiSlider-colorPrimary .MuiSlider-rail{
background: linear-gradient(90deg, #D3F5F9 0.32%, #00ACBF 49.99%, #02444C 100%);
opacity: 1;
height: 3px;
border-radius: 3px;
}
.MuiSlider-thumb::after {
top: -15px;
left: -15px;
right: -15px;
bottom: -15px;
content: "";
position: absolute;
border-radius: 50%;
}
.MuiSlider-track {
height: 2px;
display: block;
position: absolute;
border-radius: 1px;
background-color: currentColor;
}
.auth-slider .MuiSlider-colorPrimary .MuiSlider-track {
background-color: transparent;
}
.auth-slider .MuiSlider-colorPrimary .MuiSlider-mark {
background-color: #ccc;
height: 8px;
top: 23px;
}
.MuiSlider-mark {
width: 2px;
height: 2px;
position: absolute;
border-radius: 1px;
background-color: currentColor;
}
.auth-slider .MuiSlider-colorPrimary .MuiSlider-markLabel {
background-color: transparent;
top: 38px;
}
.MuiSlider-markLabel {
top: 26px;
color: rgba(0, 0, 0, 0.54);
position: absolute;
font-size: 0.875rem;
transform: translateX(-50%);
font-family: 'Roboto', sans-serif;
font-weight: 400;
line-height: 1.43;
white-space: nowrap;
}
.auth-slider .MuiSlider-colorPrimary .MuiSlider-thumb {
background-color: transparent;
margin-top: -11px;
border-radius: 0;
width: 0;
height: 0;
border-left: 7px solid transparent;
border-right: 7px solid transparent;
border-top: 8px solid #00acbf;
}
.MuiSlider-thumb {

display: flex;
outline: 0;
position: absolute;
box-sizing: border-box;
transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
align-items: center;
margin-left: -6px;
justify-content: center;
}

.auth-slider .MuiSlider-colorPrimary .MuiSlider-thumb .MuiSlider-valueLabel {
left: calc(-50% - 15px);
}

.auth-slider .MuiSlider-colorPrimary .MuiSlider-thumb > span {
top: -35px;
}


.MuiSlider-thumb::after {
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  content: "";
  position: absolute;
  border-radius: 50%;
}
input[type="hidden" i] {
appearance: none;
background-color: initial;
cursor: default;
display: none !important;
padding: initial;
border: initial;
}
.auth-slider .MuiSlider-colorPrimary .MuiSlider-thumb.Mui-focusVisible, .auth-slider .MuiSlider-colorPrimary .MuiSlider-thumb:hover {
box-shadow: none !important;
}
.meeting-slider .meeting-slider-line .MuiSlider-root {
color: transparent !important;
width: 100%;
cursor: pointer;
height: 2px;
display: inline-block;
padding: 13px 0;
position: relative;
box-sizing: content-box;
touch-action: none;
-webkit-tap-highlight-color: transparent;
}

.meeting-slider .MuiSlider-thumb span span {
  width: 45px !important;
  margin-top: 8px !important;
    font-weight: 500 !important;
  /* height: 32px;
  display: flex; */
  /* transform: rotate(-45deg); */
  /* align-items: start ; */
  /* border-radius: 50% 50% 50% 0; */
  /* justify-content: center; */
  /* background-color: currentColor; */
}

/* .makeStyles-root-11 {
width: 350px !important;
} */
/* ----------------------meeting-slider css end------------------------------ */

.Meeting-label .help-block {
  font-size: 14px;
  max-width: 320px;
}
@media (max-width: 1100px){
  .Meeting-label {
    max-width: 100%;
    margin-right: 0;
    flex: 0 0 100%;
    margin-bottom: 10px;
}
.Meeting-label .help-block {
  max-width: 100%;
}
}
@media (max-width: 480px){
.makeStyles-root-11 {
  width: 280px !important;
}
}
/* --------------------------Exclude Meetings with the Following Keywords start-------------------------------------------------- */
.chip {
  overflow: hidden;
  display: inline-block;
  max-width: 450px;
}

.chip {
  white-space: nowrap;
  vertical-align: middle;
  text-overflow: ellipsis;
}
.chip {
  padding: 11px 30px 11px 11px;
  background: #eee;
  margin: 10px 10px 0 0;
  border-radius: 4px;
}
.chip {
  font-size: 13px;
  color: #666;
  background-color: #eee;
  margin-bottom: 6px;
  margin-right: 6px;
  line-height: 20px;
  padding: 8px 8px 6px 10px;
  display: inline-block;
}
.chip .close {
  margin: 0 0 0 10px;
  font-size: 10px;
  color: #999;
  font-weight: 700;
}

.chip .close:hover {
  color: rgb(68, 67, 67);
}

.material-icons {
  font-family: fontIcons;
  display: inline-block;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.chip p {
  cursor: pointer;
  background-color: transparent;
  /* color: #800a70; */
  text-decoration: none;
}
.chip p {
  -webkit-tap-highlight-color: transparent;
}

.suggest {
  position: relative;
}
.suggest .sWrap {
  position: relative;
}
.suggest .sWrap .inpWrap {
  border: 1px solid #ddd;
  padding: 5px 5px 5px 10px;
}
.chipsContainer {
  max-height: 110px;
  overflow-y: auto;
}
input {
  border: none;
}
.inpWrap input[type="text"] {
  padding: 4px 10px;
  /* margin-bottom: 0; */
  /* border: 1px solid #ddd; */
  box-sizing: border-box;
  height: 42px;
}
.inpWrap input[type="text"] {
  background-color: transparent;
  border-radius: 0;
  outline: 0;
  width: 100%;
  font-size: 1rem;
  /* margin: 0 0 20px; */
  margin: 10px 0px 10px -7px;
  /* padding: 0; */
  box-shadow: none;
  /* box-sizing: content-box; */
  transition: all 0.3s;
}
input {
  line-height: normal;
}
input {
  color: inherit;
  font: inherit;
  /* margin: 0; */
}
input {
  writing-mode: horizontal-tb !important;

  text-rendering: auto;
  /* color: fieldtext; */
  letter-spacing: normal;
  word-spacing: normal;
  /* line-height: normal; */
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
}

.meeting-slider-line .MuiSlider-root  :nth-child(9) {
  border-bottom: 8px solid #00acbf !important;
  border-top: none !important;
  top: 30px !important;
}
.meeting-slider-line .MuiSlider-root  :nth-child(9) .MuiSlider-valueLabel {
 top: 1px !important;
}
.meeting-slider .MuiSlider-rail{
  width: 100.5% !important;
}
.sugComp{
  width: 100%;
}

@media screen and (max-width:767px)
{
  .slider-custom-styles{
    width: 240px !important;
  }
}
