.react-select1__value-container{
    background-color: #F8F9FB;
    height: 55px;
    border-radius: 3px;
}
.css-tj5bde-Svg{
    height: 5rem !important;
    padding-bottom: 2.5rem !important;
}
/* border color on focus (working code) */
/* .css-1pahdxg-control{
    box-shadow: 0 0 0 1px #32a97d !important;
} */
.css-8mmkcg{
    margin-top: 8px !important;
  }
  /* .form-control.form-control-solid{
    color: #868686 !important;

  } */


  /* ------------------------------------------ */

  
  /* IE11 hide native button (thanks Matt!) */
  /* select::-ms-expand {
  display: none;
  } */

  .form-control.form-control-solid{
    border-color: #CCCCCC !important;
  }
  
  .selectdiv:after {
    content: '>';
    font: 15px "Consolas", monospace;
    font-weight:bolder;
    color: #CCCCCC;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    /*Adjust for position however you want*/
    top: 40.8px;
    right:-4px;
    padding: 7.8px 25px 5px 22px;
    border-bottom: 1px solid #CCCCCC;
    /*left line */
    position: absolute;
    pointer-events: none;
 }

  .selectdiv select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .react-tel-input .country-code-input{
    border-color: #CCCCCC !important;
  }
  
  /* .Other:hover , .Manager:hover,.Supervisor:hover,.Director:hover,.Executive:hover,.VP:hover{
    background-color: red !important;

  } */
 /* option:hover {
    background-color: yellow !important;
    color: red !important;
  } */
  /* .selectdiv select option:hover
  {
      background-color: red !important;
      background: red !important;
  } */
 

.custom_border_shadow_react_phone-input .react-tel-input  .country-code-input:focus
 {
  box-shadow: 0 0 0 1px #00acbf !important; 
 }
 .custom_border_shadow_react_phone-input .react-tel-input .flag-dropdown 
 {
  border-radius: 5px 0 0 5px;
 }