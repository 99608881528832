  .toggle_arrow_custom {
    position: relative !important;
    border: solid #00ACBF !important;
    border-width: 0 3px 3px 0 !important;
    display: inline-block !important;
    padding: 3px !important;
  }
  .toggle_arrow_custom1 {
    position: relative !important;
    border: solid #fff !important;
    border-width: 0 3px 3px 0 !important;
    display: inline-block !important;
    padding: 3px !important;
  }
  
  
  
  .toggle_up_custom {
    left: 192px !important;
    top: 3px;
    transform: rotate(-135deg) !important;
    -webkit-transform: rotate(-135deg) !important;
  }
  
  .toggle_down_custom {
    left: 192px !important;
    top: -2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .nav_footer .dropdown-toggle::after {
    display: none !important;
  }
  /* .nav_footer .user_profile_link .btn-profile-nav:after */